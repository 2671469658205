:root {
  --color-primary: #270b82;
  --color-secondary: #ffffff;
}
aside.side-bar-wrap {
  --radius-size: 25px;
  height: 98vh;
  /* position: fixed; */
  margin-left: 5px;
  top: 1vh;
  left: 1vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: var(--radius-size);
  padding-right: 2px;
}
aside.side-bar-wrap::-webkit-scrollbar {
  /* width: 5px; */
  display: none;
}
aside.side-bar-wrap::-webkit-scrollbar-track {
  background-color: transparent;
}
aside.side-bar-wrap::-webkit-scrollbar-thumb {
  border-radius: var(--radius-size);
  background-color: var(--color-primary);
}
nav.side-bar {
  min-height: 100%;
  background-color: var(--color-primary);
  display: inline-block;
  border-left: 5px solid var(--color-primary);
  /* border-right: 5px solid var(--color-primary); */
  border-radius: var(--radius-size);
}
nav.side-bar .logo-area {
  --curve-size: calc(2 * var(--radius-size));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: var(--curve-size);
  background-color: var(--color-secondary);
  border-radius: 0 var(--radius-size) var(--radius-size) 0;
  box-shadow: var(--radius-size) 0 var(--color-secondary);
}
nav.side-bar .logo-area::after {
  content: "";
  width: var(--curve-size);
  height: var(--curve-size);
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  bottom: calc(-1 * var(--curve-size));
  left: 0;
  box-shadow: calc(-1 * var(--curve-size) * 0.5) calc(-1 * var(--curve-size) * 0.5) var(--color-secondary);
}
nav.side-bar .logo-area img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  max-height: 85%;
  transition-duration: 0.3s;
}
aside.side-bar-wrap:hover nav.side-bar .logo-area img.min {
  left: 50%;
  transform: translateX(-50%);
  max-height: 0;
  opacity: 0;
}
nav.side-bar .logo-area img.max {
  max-width: 0;
  opacity: 0;
  transition-duration: 0.3s;
}
aside.side-bar-wrap:hover nav.side-bar .logo-area img.max {
  max-width: 90%;
  opacity: 1;
}
nav.side-bar ul {
  padding: 0;
  margin: calc(2 * var(--radius-size)) 0;
  display: flex;
  flex-direction: column;
}
nav.side-bar ul li {
  height: 50px;
  padding: 10px var(--radius-size);
  list-style: none;
  border-radius: var(--radius-size);
  /* margin-bottom: var(--radius-size); */
  z-index: 1;
}
nav.side-bar ul li:not(.active) {
  z-index: 2;
}
nav.side-bar ul li:not(.active):hover {
  backdrop-filter: brightness(0.85);
}
nav.side-bar ul li.active {
  position: relative;
  background-color: var(--color-secondary);
  border-radius: var(--radius-size) 0 0 var(--radius-size);
}

nav.side-bar ul li.active b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  right: -10px;
  width:100%;
  background: #ffffff;
}
nav.side-bar ul li.active b:nth-child(1)::before {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 93.5%;
  height: 100%;
  border-bottom-right-radius: 30px;
  background: var(--color-primary);
}

nav.side-bar ul li.active b:nth-child(2) {
  position: absolute;
  bottom: -20px;
  right: -10px;
  height: 20px;
  width: 100%;
  background: #ffffff;
}
nav.side-bar ul li.active b:nth-child(2)::before {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  border-top-right-radius: 30px;
  background: var(--color-primary);
}

/* nav.side-bar ul li.active::before,
nav.side-bar ul li.active::after {
  --curve-size: calc(2 * var(--radius-size));
  content: "";
  width: var(--curve-size);
  height: var(--curve-size);
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
} */
/* nav.side-bar ul li.active::before {
  top: calc(-1 * var(--curve-size));
  left: 0;
  box-shadow: calc(-1 * var(--curve-size) * 0.5) calc(var(--curve-size) * 0.5) var(--color-secondary);
}
nav.side-bar ul li.active::after {
  bottom: calc(-1 * var(--curve-size));
  left: 0;
  box-shadow: calc(-1 * var(--curve-size) * 0.5) calc(-1 * var(--curve-size) * 0.5) var(--color-secondary);
} */
nav.side-bar ul li a {
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0;
  align-items: center;
  text-decoration: none;
  transition: 1s;
}
nav.side-bar ul li.active a {
  color: #000000;
  transition: 1s;
}
aside:hover nav.side-bar ul li a {
  gap: 10px;
}
nav.side-bar ul li a span {
  display: flex;
  transition: 0.75s cubic-bezier(0.39, 0.58, 0.57, 1);
}
nav.side-bar ul li a span.icon {
  font-size: 24pt;
}
/* nav.side-bar ul li a span.activeIcon svg {
  filter: invert(1);
} */
nav.side-bar ul li a span.title {
  max-width: 0;
  opacity: 0;
}
aside:hover nav.side-bar ul li a span.title {
  width: auto;
  max-width: 10rem;
  opacity: 1;
}